import React, { useEffect, useState } from "react";
import "./MyMarkket.css";
import { fetchUnsellectedBetData } from "../../Redux/Slices/unsellectedBetSlice";
import { fetchUnsellectedBet } from "../../Redux/api/UnsellectedAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const MyMarkeet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { unsellectedBet, isLoading, error } = useSelector(
    (state) => state.betHistory
  );
  const [tableData, setTableData] = useState([]);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const invalidToken = useSelector(
    (state) => state.unsellectedBet.invalidToken
  );

  useEffect(() => {
    if (invalidToken) {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("token");
      navigate("/");
    }
  }, [invalidToken]);

  const token = useToken();
  const userId = useUserId();

  useEffect(() => {
    const fetchunsellectedData = async () => {
      try {
        const data = {
          uid: userId,
          fromDate: "",
          toDate: "",
        };
        const response = await fetchUnsellectedBet(data, token);
        setTableData(response);
      } catch (error) {
        console.error("Error fetching unsellectedBet", error);
      }
    };

    fetchunsellectedData();
  }, [userId, token]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = tableData.slice(indexOfFirstPage, indexOfLastPost);

  const handleSearch = () => {
    const data = {
      uid: userId,
      fromDate: fromdate,
      toDate: todate,
    };
    dispatch(fetchUnsellectedBetData({ data, token }));
  };

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Unsettled Bet</h4>
            <div className="mybet_table">
              <div className="table-responsive">
                <table className="tablee">
                  <thead className="Statement_head">
                    <tr>
                      <th>No</th>
                      <th>Event Name</th>
                      <th>Nation</th>
                      <td>Match</td>
                      <th>Yes</th>
                      <th>No</th>
                      <th>Event Type</th>
                      {/* <th>Market Name</th>
                              <th>Side</th>
                              <th>Rate</th> */}
                      <th>Amount</th>
                      <th>Place Date</th>
                      {/* <th>Match Date</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentPost?.map((item, index) =>
                      item.back > 0 ? (
                        <tr
                          key={item.id}
                          style={{ backgroundColor: "#0b9cf1" }}>
                          <td>{(currentPage - 1) * listPerpage + index + 1}</td>
                          <td>{item.remark}</td>
                          <td>{item.team}</td>
                          <td>{item.name}</td>
                          <td>{item.back}</td>
                          <td>{item.lay}</td>
                          <td>{item.type}</td>
                          {/* <td>{' '}</td>
                                <td>{' '}</td>
                                <td>{' '}</td> */}
                          <td>{item.stake}</td>
                          <td>{item.edate}</td>
                          {/* <td>{''}</td> */}
                        </tr>
                      ) : (
                        <tr
                          key={item.id}
                          style={{ backgroundColor: "#f37a13 " }}>
                          <td>{(currentPage - 1) * listPerpage + index + 1}</td>
                          <td>{item.remark}</td>
                          <td>{item.team}</td>
                          <td>{item.name}</td>
                          <td>{item.back}</td>
                          <td>{item.lay}</td>
                          <td>{item.type}</td>
                          {/* <td>{' '}</td>
                                <td>{' '}</td>
                                <td>{' '}</td> */}
                          <td>{item.stake}</td>
                          <td>{item.edate}</td>
                          {/* <td>{''}</td> */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
               
              </div>
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyMarkeet;
