import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://playstar-api.nakshtech.info/",
});

export const fetchCasinobetHistory = createAsyncThunk(
  "user/fetchDashbetHistory",
  async ({ uid, token, id,todate,fromdate},{rejectWithValue}) => {
// console.log("userId",uid)
    try {
      const response = await API.post(
        "/GetCasinoBetReport",
        {
          uid: uid,
          gameid:id,
          fdate: fromdate,
          tdate: todate,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data.data[0];
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const casinobetHistorySlice = createSlice({
  name: "casinobetHistory",
  initialState: {
    casinobetHistory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCasinobetHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCasinobetHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.casinobetHistory = action.payload;
      })
      .addCase(fetchCasinobetHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default casinobetHistorySlice.reducer;
