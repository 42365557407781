import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";
import DOMPurify from "dompurify";
import axios from "axios";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

export default function BankDetails() {
  const token = useToken();

  const userId = useUserId();
  const dispatch = useDispatch();

  let userName = useUsername();
  const navigate = useNavigate();

  const [ifsc, setIFSC] = useState("");
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [account, setAccount] = useState("");
  const [holder, setHolder] = useState("");
  const [validIfsc, setValidIfsc] = useState(false);

  const [status, setStatus] = useState(1);
  const Profile = async () => {
    try {
      let res = await API.post(
        `getbankDetails_byuserid`,
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      res = res.data.data[0];
      // console.log("Profile+>", res);
      if (res && res.length > 0) {
        setAccount(res[0].accountnumber);
        setBankName(res[0].bank_name);
        setBranch(res[0].branch);
        setIFSC(res[0].ifsc);
        setHolder(res[0].holder);
        setStatus(res[0].status);
      }
    } catch (e) {
      console.log("Somthing error in Profile API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Profile();
  }, []);

  function handleIFSC(e) {
    let value = DOMPurify.sanitize(
      e.target.value.replace(/[^0-9A-Z]/gi, "").substring(0, 50)
    );
    setIFSC(value);
    Bank_API(value);
  }

  async function Bank_API(value) {
    try {
      let res = await axios.get(`https://ifsc.razorpay.com/${value}`);

      // console.log("Bank_API", res);
      if (res.status === 200) {
        setValidIfsc(true);
        setBankName(res.data.BANK);
        setBranch(res.data.ADDRESS);
      }
    } catch (e) {
      console.log("Error in rozerpay", e);
      setValidIfsc(false);
      setBankName("");
      setBranch("");
    }
  }

  const Profile_Update = async () => {
    try {
      if (!validIfsc || !ifsc) {
        toast.error("Invalid IFSC Code");
        return;
      }
      let res = await API.post(
        "updateUserBankDetails",
        {
          userId: userId,
          accountholder: holder,
          branch: branch,
          bankName: bankName,
          Ifsc: ifsc,
          accountnumber: account,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      res = res.data.data;
      // console.log("Profile_Update", res);

      if (res === "Bank Updated Successfully !!") {
        toast.success("Bank Details Updated Successfully !!");
        Profile();
      } else {
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthig error in Bank Update api ", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 p-1 px-2">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Bank Details</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card cp-card mt-4 m-2 py-2 col-lg-6">
                <div className="px-4 mb-3">
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="form-group mt-2">
                    <label htmlFor="ifsc">IFSC Code </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="ifsc"
                      placeholder="IFSC Code"
                      value={ifsc}
                      onChange={(e) => handleIFSC(e)}
                      maxLength={50}
                      readOnly={status == 0 ? false : true}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Bank Name</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Bank Name"
                      value={bankName}
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Branch</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Branch"
                      value={branch}
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="">Account Number</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Account Number"
                      value={account}
                      onChange={(e) =>
                        setAccount(
                          DOMPurify.sanitize(
                            e.target.value
                              .replace(/[^0-9]/gi, "")
                              .substring(0, 20)
                          )
                        )
                      }
                      readOnly={status == 0 ? false : true}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="">Account Holder Name</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Account Holder Name"
                      value={holder}
                      onChange={(e) =>
                        setHolder(
                          DOMPurify.sanitize(
                            e.target.value
                              .replace(/[^A-Z ]/gi, "")
                              .substring(0, 100)
                          )
                        )
                      }
                      readOnly={status == 0 ? false : true}
                    />
                  </div>
                  {status == 0 && (
                    <button
                      type="submit"
                      className="btn transfer-btn mt-4"
                      onClick={() => Profile_Update()}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
