import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../api/authAPI";
import { encryptData } from "../../Utility/libs/userEncryption";

const initialState = {
  isAuth: false,
  userId: null,
  username: null,
  token: null,
  status: null,
  error: null,
  isLoading: false,
  isPswdupdated: false,
  isDevToolsOpen: false,
  currentMatch: {},
};

// Define an asynchronous thunk for login
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password, ipaddress }, { getState, rejectWithValue }) => {
    try {
      let state = getState();
      const isDevToolsOpen = state.auth.isDevToolsOpen;
      if (isDevToolsOpen) {
        return rejectWithValue("DevTools are open. API calls are blocked.");
      }

      const response = await login({ username, password, ipaddress });
      if (response.data?.data?.result === "Successfull") {
        return response.data;
      } else {
        return rejectWithValue(response.data.data.result);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: () => {
      return initialState;
    },
    updateCurrentMatch(state, action) {
      state.currentMatch = action.payload;
    },
    Authenticate(state) {
      state.isPswdupdated = true;
    },
    setDevToolsOpen: (state, action) => {
      state.isDevToolsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userId = encryptData(action.payload.data.resultid);
        state.username = encryptData(action.payload.data.resultusername);
        state.token = encryptData(action.payload.token);
        state.status = encryptData(action.payload.data.loginstatus);
        state.isAuth = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { logoutUser, updateCurrentMatch, Authenticate, setDevToolsOpen } =
  authSlice.actions;

export default authSlice.reducer;
