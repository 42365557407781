import React, { lazy, useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";

import "./App.css";

import Home from "./Components/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import Sport from "./Components/SportNew/Sport";
import WalletSignIn from "./Components/WalletSignin/WalletSignIn";
import MyMarkeet from "./Components/MyMarkeet/MyMarkeet";
import BettingHistory from "./Components/BettingHistory/BettingHistory";
import FundsTransfer from "./Components/FundsTransfer/FundsTransfer";
import WithdrawHistory from "./Components/WithdrawHistory/WithdrawHistory";
import Login from "./Components/Login/Login";
// import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import AccountStatement from "./Components/AccountStatement/AccountStatement";
import WithdrawalStatement from "./Components/AccountStatement/WithdrawalStatement";
import TransactionHistory from "./Components/TransactionHistory/TransactionHistory";
import ProfitLoss from "./Components/ProfitLoss/ProfitLoss";
import CasinoResults from "./Components/CasinoResults/CasinoResults";
import LiveCasino from "./Components/LiveCasino/LiveCasino";
import Deposit from "./Components/Deposit/Deposit";
import InPlay from "./Components/InPlay/InPlay";
import ChangePassword from "./Components/ChangePassword/ChangePassword";
import ColorGame from "./Components/ColorGame/ColorGame";
import Aviator from "./Components/AviatorNew/Aviator";
import TeenPatti from "./Components/TeenPatti/TeenPatti";
import ThirtyTwoCards from "./Components/32Cards/ThirtyTwoCards";
import CardsB from "./Components/32cardsB/CardsB";
import DragonTiger from "./Components/DragonTiger/DragonTiger";
import Lucky from "./Components/Lucky7A/Lucky";
import Footer from "./Components/Footer/Footer";
import { Provider, useDispatch } from "react-redux";
import { store } from "./Redux/Store/store";
import { ToastContainer, toast } from "react-toastify";
import Withdrawal from "./Components/WithdrawelUSDT/WithDrawal";
import DepositHistory from "./Components/Deposit History/DepositHistory";
import PrivateRoutes from "./Utility/PrivateRoutes";
import Profile from "./Components/Profile/Profile";
import Iframe from "./Components/Iframe/Iframe";
import DragonTiger2 from "./Components/DragonTiger/DragonTiger2";
// import Andarbahar from "./Components/Andarbahar/Andarbahar";
import AviatorIframe from "./Components/Iframe/AviatorIframe";
import Component from "./Components/Iframe/ProxyIframe";
import AviatorBetHistory from "./Components/Aviator History/AviatorBetHistory";
import AviatorResult from "./Components/Aviator History/AviatorResult";
import LokSabha from "./Components/LokSabha/LokSabha";
import Ipl2024 from "./Components/Ipl2024/Ipl2024";
import Roulette from "./Components/Roulette/Roulette";
import Button_value from "./Components/ButtonValue/Button_value";
import FundTransferHistory from "./Components/FundsTransfer/FundTransferHistory";
import FootballSports from "./Components/Sports Football/FootballSports";
import TennisSport from "./Components/Sport Tennis/TennisSport";
import Registration from "./Components/Registration/Registration";
import LeaderShipIncome from "./Components/Income/LeadershipIncome";
import RewardIncome from "./Components/Income/RewardIncome";
import LoginBonus from "./Components/Income/loginBonus";
import LevelIncome from "./Components/Income/LevelIncome";
import JackPotAchievers from "./Components/Income/JackpotAchievers";
import DownlineRcharge from "./Components/Income/DownlineRecharge";
import FirstRechargebonus from "./Components/Income/FirstRechargebonus";
import DirectSignupbonus from "./Components/Income/DirectSignupbonus";
import Signupbonus from "./Components/Income/SignupBonus";
import MyTeam from "./Components/Reports/MyTeam";
import WeeklyIncomeReport from "./Components/Income/WeeklyIncomeReport";
import MyReferral from "./Components/Reports/MyReferral";
import LegBussiness from "./Components/Reports/LegBussinessReport";
import TeamRechargebonus from "./Components/Income/TeamRechargebonus";
import LeadershipMonthlySaleryincome from "./Components/Income/LeadershipMonthlySaleryincome";
import UplineSalaryincome from "./Components/Income/UplineSalaryincome";
import DailySaleryincome from "./Components/Income/DailySaleryincome";
import DepositBonus from "./Components/Income/DepositBonus";
import Mlmdashboard from "./Components/MLM Dashboard/Mlmdashboard";
import ConvertintoGameWallet from "./Components/Reports/ConvertintoGameWallet";
import ConvertIntoGameWalletHistory from "./Components/Reports/ConvertIntoGameWalletHistory";
import BetWiningBonus from "./Components/Reports/BetWiningbonus";
import BankDetails from "./Components/Profile/BankDetails";
import InternetStatusChecker from "./Components/InternetStatusChecker";
import { logoutUser, setDevToolsOpen } from "./Redux/Slices/authSlice";
// import { useDevToolsStatus } from "./Utility/hooks/useDevToolsDetector";

const Andarbahar = lazy(() => import("./Components/Andarbahar/Andarbahar"));

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [locationSidebar, setlocationSidebar] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const isDevToolsOpen = useDevToolsStatus();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  useEffect(() => {
    const location = window.location.pathname;

    setlocationSidebar(!location.includes("iframe"));
  }, []);

  // useEffect(() => {
  //   dispatch(setDevToolsOpen(isDevToolsOpen));

  //   if (isDevToolsOpen) {
  //     dispatch(logoutUser());
  //     navigate("/login");
  //   }
  // }, [dispatch, isDevToolsOpen, navigate]);

  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleStorageChange = () => {
  //     setRedirected(true);
  //     dispatch(logoutUser());
  //     navigate("/login");
  //   };
  //   const handleResize = () => {
  //     if (
  //       window.outerWidth - window.innerWidth > 100 ||
  //       window.outerHeight - window.innerHeight > 100
  //     ) {
  //       toast.error("PLease Close Developer Tools");
  //       setRedirected(true);
  //       dispatch(logoutUser());
  //       navigate("/login");
  //     }
  //   };

  //   window.addEventListener("contextmenu", handleContextMenu);
  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("contextmenu", handleContextMenu);
  //     window.removeEventListener("resize", handleResize);
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, [dispatch, navigate]);

  return (
    <div className=" bges position-relative row app-row ">
      <InternetStatusChecker />
      <ToastContainer />
      {locationSidebar && (
        <>
          <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

          <Sidebar
            handleLinkClick={handleLinkClick}
            showSidebar={showSidebar}
          />
          {/* <Footer /> */}
        </>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/WalletSignIn" element={<WalletSignIn />} />
        <Route path="/iframe/:Id" element={<Iframe />} />
        <Route path="/AViframe/:Url" element={<AviatorIframe />} />
        <Route path="/Component/:id" component={Component} />
        <Route element={<PrivateRoutes />}>
          <Route path="/InPlay" element={<InPlay />} />
          <Route path="/Sport/:id" element={<Sport />} />
          <Route path="/dashboard" element={<Mlmdashboard />} />
          <Route path="/Football/:id" element={<FootballSports />} />
          <Route path="/Tennis/:id" element={<TennisSport />} />
          <Route path="/MyMarkeet" element={<MyMarkeet />} />
          <Route path="/BettingHistory" element={<BettingHistory />} />
          <Route path="/FundsTransfer" element={<FundsTransfer />} />
          <Route
            path="/FundsTransferHistory"
            element={<FundTransferHistory />}
          />
          <Route path="/WithdrawalUSDT" element={<Withdrawal />} />
          <Route path="/WithdrawalHistory" element={<WithdrawHistory />} />
          <Route path="/AccountStatement" element={<AccountStatement />} />
          <Route
            path="/WithdrawalStatement"
            element={<WithdrawalStatement />}
          />
          <Route path="/TransactionHistory" element={<TransactionHistory />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/bankDetails" element={<BankDetails />} />
          <Route path="/Ipl2024" element={<Ipl2024 />} />
          <Route path="/ProfitLoss" element={<ProfitLoss />} />
          <Route path="/CasinoResults" element={<CasinoResults />} />
          <Route path="/Deposit" element={<Deposit />} />
          // <Route path="/DepositHistory" element={<DepositHistory />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/LiveCasino" element={<LiveCasino />} />
          <Route path="/Roulette" element={<Roulette />} />
          <Route path="/ColorGame" element={<ColorGame />} />
          <Route path="/Aviator" element={<Aviator />} />
          <Route path="/AviatorbetHistory" element={<AviatorBetHistory />} />
          <Route path="/AviatorResult" element={<AviatorResult />} />
          <Route path="/TeenPatti/:id" element={<ThirtyTwoCards />} />
          <Route path="/32Cards/:id" element={<TeenPatti />} />
          {/* <Route path="/32CardsB" element={<CardsB />} /> */}
          <Route path="/DragonTiger/:id" element={<DragonTiger2 />} />
          {/* <Route path="/DragonTiger2" element={<DragonTiger />} /> */}
          <Route path="/Andar_Bahar/:id" element={<Andarbahar />} />
          <Route path="/Lucky7A/:id" element={<Lucky />} />
          <Route path="/LokSabha" element={<LokSabha />} />
          <Route path="/ButtonValue" element={<Button_value />} />
          <Route path="/leadershipIncome" element={<LeaderShipIncome />} />
          <Route
            path="/LeadershipMonthlySaleryincome"
            element={<LeadershipMonthlySaleryincome />}
          />
          <Route path="/UplineSalaryincome" element={<UplineSalaryincome />} />
          <Route path="/DailySaleryincome" element={<DailySaleryincome />} />
          <Route path="/rankIncome" element={<RewardIncome />} />
          <Route path="/loginBonus" element={<LoginBonus />} />
          <Route path="/LevelincomeTraders" element={<LevelIncome />} />
          <Route path="/JackpotAchiver" element={<JackPotAchievers />} />
          <Route path="/FirstRechargebonus" element={<FirstRechargebonus />} />
          <Route path="/TeamRechargebonus" element={<TeamRechargebonus />} />
          <Route path="/DirectSignupbonus" element={<DirectSignupbonus />} />
          <Route path="/Signupbonus" element={<Signupbonus />} />
          <Route path="/myTeam" element={<MyTeam />} />
          <Route path="/myReferral" element={<MyReferral />} />
          <Route path="/LegBussiness" element={<LegBussiness />} />
          <Route path="/depositBonus" element={<DepositBonus />} />
          <Route path="/ConvertIntoGame" element={<ConvertintoGameWallet />} />
          <Route
            path="/ConvertIntoGameHistory"
            element={<ConvertIntoGameWalletHistory />}
          />
          <Route path="/WeeklyIncomeReport" element={<WeeklyIncomeReport />} />
          <Route path="/betWinningBonus" element={<BetWiningBonus />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
