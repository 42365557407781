import React, { useEffect, useState } from "react";
import MatchOdds from "./MatchOdds";
import BookMaker from "./BookMaker";
import { io } from "socket.io-client";
import Session from "./Session";
import { useSelector } from "react-redux";
import SportModal from "./SportModal";

const OpenMarkeet = () => {
  const SOCKET_URL = "https://playstar-socket-api.nakshtech.info/";

  const [socketData, setSocketData] = useState({});
  const [realtimeData, setRealtimeData] = useState(false);
  const { id } = useSelector((state) => state.auth.currentMatch);

  useEffect(() => {
    // console.log("before odds")
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketAllOddMatchType", id);
    cricketMatchesSocket.on("CricketAllOddMatchType_FromAPI", (data) => {
      let parsedata = JSON.parse(data);
      // console.log("match_data", parsedata);
      if (parsedata.statusCode === 200) {
        setSocketData(parsedata.data);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  const [modal, setModal] = useState(false);
  const [marketType, setmarketType] = useState(null);
  const [betType, setbetType] = useState(null);
  const [betDetails, setbetDetails] = useState({});
  const [countryName, setcountryName] = useState(null);
  const [show, setShow] = useState(0);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="row  bg-dark ">
      <div className="betting-info">
        <button
          className="betting-info-btn px-2 bg-dark"
          onClick={() => setShow(0)}
        >
          OPEN MARKET
        </button>
        <button
          className="betting-info-btn px-2 bg-dark"
          onClick={() => setShow(1)}
        >
          ODDS
        </button>
        <button
          className="betting-info-btn px-2 bg-dark"
          onClick={() => setShow(2)}
        >
          BOOKMAKER
        </button>
        <button
          className="betting-info-btn px-2 bg-dark"
          onClick={() => setShow(3)}
        >
          FANCY
        </button>
      </div>

      {(show === 0 || show === 1) &&
        socketData &&
        Object.keys(socketData).length > 0 && (
          <MatchOdds
            data={socketData?.matchOdds}
            handleModal={handleModal}
            setmarketType={setmarketType}
            setbetType={setbetType}
            setbetDetails={setbetDetails}
            setRealtimeData={setRealtimeData}
            marketType={marketType}
            betType={betType}
            realtimeData={realtimeData}
          />
        )}
      {(show === 0 || show === 2) &&
        socketData &&
        Object.keys(socketData).length > 0 &&
        socketData.bookMakerOdds &&
        socketData.bookMakerOdds.length > 0 && (
          <BookMaker
            data={socketData?.bookMakerOdds[0].bm1}
            handleModal={handleModal}
            setmarketType={setmarketType}
            setbetType={setbetType}
            setbetDetails={setbetDetails}
            setRealtimeData={setRealtimeData}
            marketType={marketType}
            betType={betType}
            realtimeData={realtimeData}
          />
        )}

      {(show === 0 || show === 3) &&
        socketData.fancyOdds &&
        socketData.fancyOdds.length > 0 && (
          <Session
            setbetDetails={setbetDetails}
            setmarketType={setmarketType}
            setbetType={setbetType}
            handleModal={handleModal}
            setcountryName={setcountryName}
            setrealtimeData={setRealtimeData}
            marketType={marketType}
            realtimeData={realtimeData}
            betType={betType}
            data={socketData.fancyOdds.filter((item) => item.gtype == "Normal")}
          />
        )}

      {modal && (
        <SportModal
          setModal={setModal}
          setbetType={setbetType}
          setmarketType={setmarketType}
          setbetDetails={setbetDetails}
          betDetails={betDetails}
          betType={betType}
          marketType={marketType}
          countryName={countryName}
          realtimeData={realtimeData}
          setRealtimeData={setRealtimeData}
        />
      )}
    </div>
  );
};

export default OpenMarkeet;
