import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://playstar-api.nakshtech.info/",
});

export const resultSchedulerAPI = createAsyncThunk(
  "casino/resultScheduler",
  async ({ token }, { rejectWithValue }) => {
    try {
      await API.post(
        "/Schulder_32CareGameLive",
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const resultSchedulerSlice = createSlice({
  name: "resultScheduler",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resultSchedulerAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resultSchedulerAPI.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resultSchedulerAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default resultSchedulerSlice.reducer;
