import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://playstar-api.nakshtech.info/";

const API = axios.create({
  baseURL,
});

export const calculateProfit = createAsyncThunk(
  "calculate/profit",
  async ({ stake, marketType, back, lay ,token}, { rejectWithValue }) => {
    try {
      const result = await API.get(
        `/PlaceBetCalculater?mname=${marketType}&stake=${stake}&Layprice=${lay}&Backprice=${back}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("profitres",result.data)
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
