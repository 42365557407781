// authAPI.js
import axios from "axios";

const baseURL = "https://playstar-api.nakshtech.info/";
// const baseURL = "http://localhost:9477/";

const authAPI = axios.create({
  baseURL,
});

export const login = (userData) => authAPI.post("/login", userData);

export default authAPI;
