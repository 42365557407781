import React from "react";
// import new1 from "../../Media/new1.png";
// import new2 from "../../Media/new2.jpeg";
// import new3 from "../../Media/new3.jpg";

// import aviator from "../../Media/aviator.jpg";
// import color from "../../Media/color prediction game.jpg";
// import dragon from "../../Media/dragon-tigers.jpg";
// import teen from "../../Media/teen-patti.jpg";
// import andar from "../../Media/andar-bahar.jpg";
// import lucky from "../../Media/lucky7banner.jpg";
// import cards from "../../Media/32cards.jpg";
// import cricket from "../../Media/cricket.jpg";
// import img8 from "../../Media/img8.png";
// import img9 from "../../Media/img9.png";
// import img10 from "../../Media/img10.png";
// import img11 from "../../Media/img11.png";
// import img12 from "../../Media/img12.jpg";

import banner from "../../Media/MLM dashB/banner.png"
import banner1 from "../../Media/MLM dashB/banner1.png"
import banner2 from "../../Media/MLM dashB/banner2.png"
import banner3 from "../../Media/MLM dashB/banner3.png"
import banner4 from "../../Media/MLM dashB/banner4.png"
import banner5 from "../../Media/MLM dashB/banner5.png"
import banner6 from "../../Media/MLM dashB/banner6.png"
import banner7 from "../../Media/MLM dashB/banner7.png"
import banner8 from "../../Media/MLM dashB/banner8.png"
import banner9 from "../../Media/MLM dashB/banner9.png"

import Slider from "react-slick";
import "./Slider.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const SliderH = () => {
  const settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    prevArrow: false, 
    nextArrow: false, 
    

   
  };
  
  return (
    
      <div className="card slider-card bges">
      <Slider {...settings} className="sliderss">
      
        <img src={banner} alt="img" className=" slider-img img-fluid" />
        <img src={banner1} alt="img" className="slider-img img-fluid" />
        <img src={banner2} alt="img" className="slider-img img-fluid" />
        <img src={banner3} alt="img" className="slider-img img-fluid" />
        <img src={banner4} alt="img" className="slider-img img-fluid" />
        <img src={banner5} alt="img" className="slider-img img-fluid" />
        <img src={banner6} alt="img" className="slider-img img-fluid" />
        <img src={banner7} alt="img" className="slider-img img-fluid" />
        <img src={banner8} alt="img" className="slider-img img-fluid" />
        <img src={banner9} alt="img" className="slider-img img-fluid" />
        
  
      </Slider>
     
   
      </div>

    


  )
}

export default SliderH
