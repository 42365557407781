import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";
import { MdCancel } from "react-icons/md";

// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";
import { encryptData } from "../../Utility/libs/encryption";


export default function AviatorButton({
  socketData,
  sethistoryBoolean,
  historyBoolean,
  loader,
}) {
  const token = useToken();
  const userId = useUserId();
  const username = useUsername();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [cashOutModal, setCashOutModal] = useState(false);
  const [cashoutDetail, setCashOutDetail] = useState({});
  const [AButton, setAButton] = useState(0);
  const [force_st, setForce_st] = useState(false);
  const [betData, setBetData] = useState({
    periodId: "",
    amount: "",
    cashout_amount: "",
  });

  // status==0---BET
  // status==1---Cancel
  // status==2---CashOut

  useEffect(() => {
    if (
      Number(betData.periodId) > Number(socketData.period) &&
      Number(socketData.status) > 0
    ) {
      setAButton(1);
    } else if (
      betData.periodId === socketData.period &&
      socketData.status == 0
    ) {
      setAButton(1);
    } else if (
      socketData.status == 1 &&
      socketData.betData != "" &&
      betData.periodId != ""
    ) {
      setAButton(2);
    } else if (
      socketData.CheckPeriodNo == "false" &&
      socketData.status >= 0 &&
      socketData.betData == "" &&
      Number(betData.periodId) == Number(socketData.period)
    ) {
      setAButton(0);
    }
    if (
      (socketData.period == betData.periodId && socketData.status == 2) ||
      (Object.keys(betData).length > 0 &&
        socketData.period - betData.periodId > 1) ||
      socketData.period > betData.periodId
    ) {
      // console.log("cashout_check");
      setAButton(0);
      setBetData({});
    }

    if (
      Object.keys(betData).length == 0
      // || socketData.timer == "1000.00"
    ) {
      setAButton(0);
    }
  }, [betData, socketData, AButton, force_st]);

  // console.log("status", socketData.status, socketData.period, betData.periodId);
  useEffect(() => {
    setBetData({});
  }, [force_st]);

  const BetPlace_API = async () => {
    try {
      if (loader) {
        toast.error("Wait For Next Round..");
        return;
      }
      if (count < 10) {
        toast.error("Minimum Bet amount is 10 !!");
        return;
      }
      if (socketData.period === 0) {
        toast.error("Game not Started yet !");
        return;
      }

      let modPeriod =
        socketData.status === 0
          ? socketData.period
          : parseInt(socketData.period) + 1;
      let body = await encryptData({
        uid: userId,
        periodno: modPeriod,
        amount: count,
        Gameid: 2,
      });
      const response = await API.post(
        `/AviatorBetPlace_ENC`,
        {
          encryptedData: body,
          username,
        },
        { headers: { Authorization: token } }
      );

      const { data } = response.data;

      if (data === "Bet Placed.") {
        if (socketData.status === 0) {
          setBetData({
            periodId: socketData.period,
            amount: count,
            cashout_amount: "",
          });
        } else {
          setBetData({
            periodId: parseInt(socketData.period) + 1,
            amount: count,
            cashout_amount: 0,
          });
        }
        toast.success("Successfully Bet Placed");
        sethistoryBoolean(!historyBoolean);
        // dispatch(fetchuserBalance({ id: userId, token }));
      } else {
        toast.error(data);
      }
    } catch (e) {
      console.log("Error While Fetch BetPlace_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const Cashout_API = async () => {
    try {
      let body = await encryptData({
        uid: userId,
        periodno: betData.periodId,
        multiplayer: socketData.timer,
        Gameid: 2,
      });
      let res = await API.post(
        `/AviatorCashOut_ENC`,
        {
          encryptedData: body,
          username,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const data = res.data.data;
      // console.log("cashoutres->", data);
      if (data === "Cash Out") {
        setAButton(0);
        toast.success(res.data.data);
        setForce_st(!force_st);
        setBetData({});

        setCashOutModal(true);
        sethistoryBoolean(!historyBoolean);
        // dispatch(fetchuserBalance({ id: userId, token }));
        setCashOutDetail({
          amount: count,
          multiplayer: socketData.timer,
        });
      } else if (
        data === "Period Number Not Exists" ||
        data === "Send Already Cash Out"
      ) {
        toast.error(res.data.data);
        setCashOutModal(false);
        setForce_st(!force_st);
        setCashOutDetail({});

        sethistoryBoolean(!historyBoolean);
        setAButton(0);
        // dispatch(fetchuserBalance({ id: userId, token }));
      } else {
        toast.error(data);
      }
    } catch (e) {
      console.log("Error While Fetch Cashout_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const AvbetCancle_API = async () => {
    try {
      let body = await encryptData({
        uid: userId,
        periodno: betData.periodId,
        Gameid: 2,
      });
      let res = await API.post(
        `/AviatorBetCancel_ENC`,
        { encryptedData: body, username },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("cancle", res);
      const data = res.data.data;
      // console.log("cancleres->", data);
      if (data === "Cancel Bet") {
        toast.success(res.data.data);
        setForce_st(!force_st);
        setBetData({});
        sethistoryBoolean(!historyBoolean);

        setAButton(0);
        // dispatch(fetchuserBalance({ id: userId, token }));
      } else if (data == "Period Number Not Exists") {
        toast.error(res.data.data);
        setForce_st(!force_st);
        setBetData({});

        sethistoryBoolean(!historyBoolean);
        setAButton(0);
        // dispatch(fetchuserBalance({ id: userId, token }));
      } else {
        toast.error(data);
      }
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const handleCashoutModal = () => {
    setCashOutModal(false);
    setCashOutDetail({});
  };
  const CashOutModal = () => {
    return (
      <>
        <div
          className="cashout-modal-wrapper"
          onClick={() => handleCashoutModal()}
        ></div>
        <div className="cashout-modal-container">
          <div className="py-2 px-4 insection">
            <div className="p-1">
              <h6>You have crashed out !</h6>
              <p className="mb-0">{cashoutDetail.multiplayer}x</p>
            </div>
            <div className="px-sm-5 px-4 py-2 rounded-pill green_bg">
              <h6>you got :</h6>
              <p className="mb-0">
                {(cashoutDetail.multiplayer * cashoutDetail.amount).toFixed(2)}
              </p>
            </div>
            <div className="p-1 cloce_bt" onClick={() => handleCashoutModal()}>
              <MdCancel />
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    let timerout;

    if (cashOutModal) {
      timerout = setTimeout(() => {
        handleCashoutModal();
      }, 2000);
    }

    return () => {
      clearTimeout(timerout);
    };
  }, [cashOutModal]);

  return (
    <>
      <div className="col-md-8 text-center mx-auto">
        <div className="bet-btn-main-div mt-0 ">
          <div className="row mt-4">
            <div className="col-md-9 col-sm-12 mx-auto">
              <div className="row">
                <div className="col-5  d-flex justify-content-center flex-column  ">
                  <div className="counter-div d-flex w-100 justify-content-between p-1">
                    <div className="count-div d-flex justify-content-center">
                      <p>{count}</p>
                    </div>
                    <div className="d-flex count-icons my-auto">
                      <FaMinus
                        onClick={() => {
                          setCount((count) => count - 10);
                        }}
                        className="m-icon me-1"
                      />
                      <FaPlus
                        onClick={() => {
                          setCount((count) => count + 10);
                        }}
                        className="p-icon"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row mt-1">
                      <button
                        className="col Amount-btn m-1"
                        onClick={() => {
                          setCount(100);
                        }}
                      >
                        100
                      </button>
                      <button
                        className="col Amount-btn m-1"
                        onClick={() => {
                          setCount(200);
                        }}
                      >
                        200
                      </button>
                    </div>
                    <div className="row mt-1">
                      <button
                        className="col Amount-btn mx-1"
                        onClick={() => {
                          setCount(500);
                        }}
                      >
                        500
                      </button>
                      <button
                        className="col Amount-btn mx-1"
                        onClick={() => {
                          setCount(1000);
                        }}
                      >
                        1000
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-7 d-flex justify-content-start mx-auto">
                  <div className="row">
                    {AButton === 0 && (
                      <button
                        type="button"
                        className="btn btn-success bet-button betting-btn my-auto"
                        onClick={() => BetPlace_API()}
                        disabled={loader}
                      >
                        BET
                      </button>
                    )}

                    {AButton == 1 && (
                      <button
                        type="button"
                        className=" btn btn-danger bet-button cancel-button mt-1"
                        onClick={() => AvbetCancle_API()}
                        disabled={loader}
                      >
                        Cancel
                      </button>
                    )}

                    {AButton === 2 && (
                      <button
                        type="button"
                        className="btn btn-warning bet-button mt-1 cashOut"
                        onClick={() => Cashout_API()}
                        disabled={loader || socketData.timer == "1000.00"}
                      >
                        <div>Cash Out</div>
                        <div>
                          {socketData.timer == "0.1" ||
                          socketData.timer == "1000.00"
                            ? "Blast"
                            : socketData.timer}
                          {socketData.timer == "0.1" ||
                          socketData.timer == "1000.00"
                            ? ""
                            : "x"}{" "}
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {cashOutModal && <CashOutModal />}
        </div>
      </div>
    </>
  );
}
