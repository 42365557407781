import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../Media/logo.png";
import "./Registration.css";
import { toast } from "react-toastify";
import { API } from "../../API/API";

export default function Registration() {
  const [bookiename, setbookiename] = useState("");
  const [sponser, setSponser] = useState("");

  const [username, setUsername] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setlname] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [re, setRe] = useState("");
  const [country, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);
  const navigate = useNavigate();
  const referral_API = async () => {
    try {
      if (password != re) {
        toast.error("Confirm Password is not match");
        return;
      } else if (!fname) {
        toast.error("First Name is required");
        return;
      } else if (fname.length < 4) {
        toast.error("First Name must be at least 4 characters");
        return;
      } else if (fname.length > 20) {
        toast.error("First Name should not be greater than 20 characters");
        return;
      } else if (!lname) {
        toast.error("Last Name is required");
        return;
      } else if (lname.length < 5) {
        toast.error("Last Name must be at least 5 characters");
        return;
      } else if (mobile.length <= 9) {
        toast.error("Enter Valid Mobile Number !!");
        return;
      } else if (!valid) {
        toast.error("Please enter a valid email address");
        return;
      } else if (!password) {
        toast.error("Password is required");
        return;
      } else if (password.length < 6) {
        toast.error("Password must be at least 6 characters");
        return;
      }

      
      let responceRight = await API.post("/registration_new", {
        username: fname,
        f_name: fname,
        m_name: "",
        l_name: lname,
        email: email,
        mobile: mobile,
        password: password,
        country: "",
        sponser_id: bookiename,
        referby: bookiename,
        regby: "USER",
      });
      console.log("My_Team", responceRight);
      if (responceRight.data.data.result == "Successfull") {
        alert(responceRight.data.data.return_result);
        navigate("/login");
      } else {
        toast.error(responceRight.data.data.result);
      }
    } catch (e) {
      console.log("Error While calling Myteam API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        // dispatch(updateAuth({ AdminAuth: false, jwt_token: "" }));
        // sessionStorage.clear();
        // localStorage.clear();
        // navigate("/");
      }
    }
  };

  const EmailValidation = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    if (inputValue) {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[^\s@]{3,}@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(inputValue);
      setValid(isValid);
    } else {
      setValid(true);
    }
  };

  const handleSponserId = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/gi, "").substring(0, 6);
    setbookiename(newValue);
    check_sponser(newValue);
  };

  async function check_sponser(value) {
    try {
      let res = await API.post("check_sponsorid", {
        uid: value,
      });
      console.log("sponser", res);
      setSponser(res.data.data.result);
    } catch (e) {
      console.log("error in check_sponser", e);
    }
  }
  const Mobile_Data = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/gi, "");
    setMobile(newValue);
  };

  const handleName = (event) => {
    const newValue = event.target.value
      .replace(/[^A-Z]/gi, "")
      .substring(0, 30);
    if (event.target.name == "fname") {
      setFname(newValue);
    } else {
      setlname(newValue);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  return (
    <>
      <div className="background-img">
        <div className="d-flex justify-content-center align-items-center">
          <div className="card registration-card col-12 col-md-6 mt-5">
            <div className="d-flex justify-content-center">
              <img className="img-fluid img-div" src={logo1} alt="a" />
            </div>
            {/* <form > */}
            <div className="row ">
              <div className="col-6 ">
                <div className="col-12 ">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponser ID
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Enter Sponser ID"
                    aria-describedby="emailHelp"
                    value={bookiename}
                    onChange={(e) => {
                      handleSponserId(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="col-12">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponsor Name
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Sponsor Id doesnot exists !!"
                    aria-describedby="emailHelp"
                    value={sponser}
                    readOnly
                 
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="col-12 ">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    name="fname"
                    value={fname}
                    onChange={(e) => handleName(e)}
                    placeholder="Enter First Name"
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className=" registration-input p-1 px-2"
                      name="lname"
                      value={lname}
                      placeholder="Enter Last Name"
                      onChange={(e) => handleName(e)}
                      maxLength={15}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mx-1">
                <div className="col-6 ">
                  <div className="col-12  mx-1">
                    <button className="btn login-btn  col-12">Send OTP</button>
                  </div>
                </div>
                <div className="col-6">
                  <div className="col-12 mx-1">
                    <button className="btn login-btn  col-12">
                      Resend OTP
                    </button>
                  </div>
                </div>
              </div> */}
            <div className="row ">
              <div className="col-6">
                <div className="col-12">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputPassword1"
                    value={mobile}
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    onChange={Mobile_Data}
                    maxLength={15}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="col-12 ">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className=" registration-input p-1 px-2"
                    id="exampleInputPassword1"
                    name="email"
                    value={email}
                    placeholder="Enter Email Id"
                    onChange={EmailValidation}
                    maxLength={50}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-6">
                <div className="col-12">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className=" registration-input p-1 px-2"
                    id="exampleInputPassword1"
                    value={password}
                    name="pass"
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="col-12 ">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className=" registration-input p-1 px-2"
                    id="exampleInputPassword1"
                    value={re}
                    name="re"
                    placeholder="Re-Enter Password"
                    onChange={(e) => setRe(e.target.value)}
                    maxLength={20}
                  />
                </div>
              </div>
            </div>
            {/* <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Default checkbox
                </label>
              </div> */}
            <div className="row">
            <div className="col-md-12">

              <button className="btn login-btn mt-2" onClick={referral_API}>
                Registration
              </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
              <div className="bth d-flex justify-content-center mt-2">
              Back to &nbsp;{" "}
              <span>
                <Link className="nav-link" to="/">
                  {" "}
                  Home
                </Link>{" "}
              </span>
            </div>
              </div>
              <div className="col-md-6">
              <div className="bth d-flex justify-content-center mt-2">
              Already have an Account  &nbsp;{" "} 
              <span>
                <Link className="nav-link" to="/login">
                  login{" "}
                  
                </Link>{" "}
              </span>
            </div>

              </div>
            </div>
           

            
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
}
