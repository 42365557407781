import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoutes() {
  const { isAuth, isPswdupdated, token } = useSelector((state) => state.auth);

  return (
    <>
      <Suspense>
        {isAuth === true &&
        isPswdupdated === true &&
        token &&
        token.length > 50 ? (
          <Outlet />
        ) : (
          <Navigate to="/Login" />
        )}
      </Suspense>
    </>
  );
}
