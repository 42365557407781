import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const TeamRechargebonus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);
  const [level, setLevel] = useState(0);

  const userId = useUserId();
  const token = useToken();
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.post(
        `DownlineRechargeBonus`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          lvl: level,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("LeaderShip-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            fromid: item.fromid,
            income: item.income,
            dd: item.dd,
            remark: item.remark,
            Onamount: item.Onamount,
            lvl:item.lvl
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "Fromid", accessor: "fromid" },
      { Header: "Recharge Amount", accessor: "Onamount" },
      { Header: "Bonus", accessor: "income" },
      { Header: "Level", accessor: "lvl" },
      { Header: "Date", accessor: "dd" },
      // { Header: "Remark", accessor: "remark" },
    ],
  });

  // const handleSearch = () => {
  //   const data = {
  //     uid: userId,
  //     fromDate: fromdate,
  //     toDate: todate,
  //   };
  //   dispatch(fetchAccountStatement({ data, token }));
  // };

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Team Recharge Bonus</h4>

            <div className="dates-div p-2">
              <div className="row">
                <div className="col-md-1 mt-1">
                  <div className="">
                    <div className="from">
                      <h3 className="text-black">Level</h3>
                    </div>
                    
                  </div>
                </div>
                <div className="col-md-4 mt-1">
                <div className="">
                      <select
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                        style={{width:"90%"}}
                      >
                        <option value="0">Select All</option>
                        <option value="1">Level 1</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                        <option value="4">Level 4</option>
                        <option value="5">Level 5</option>
                        <option value="6">Level 6</option>
                        <option value="7">Level 7</option>
                        <option value="8">Level 8</option>
                        <option value="9">Level 9</option>
                        <option value="10">Level 10</option>
                        <option value="11">Level 11</option>
                        <option value="12">Level 12</option>
                        <option value="13">Level 13</option>
                        <option value="14">Level 14</option>
                        <option value="15">Level 15</option>
                        <option value="16">Level 16</option>
                        <option value="17">Level 17</option>
                        <option value="18">Level 18</option>
                        <option value="19">Level 19</option>
                        <option value="20">Level 20</option>
                      </select>
                    </div>
                </div>

                <div className="col-md-4 mx-3 mt-1">
                  <button
                    className="btn btn-success d-flex"
                    onClick={() => referral_API()}
                  >
                   
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamRechargebonus;
