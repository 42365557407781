import React, {  useState } from "react";
import { MdScreenshotMonitor } from "react-icons/md";
import { useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const LiveTvSection = () => {
  const { id } = useParams();

  const token = useToken();
  const userId = useUserId();
  const { dashbetHistory } = useSelector((state) => state.dashbetHistory);

  const [active, setActive] = useState(true);

  return (
    <div className="px-1 d-lg-block d-none">
      <div className="TV-outer-div px-3 py-2">
        <div className={active ? "live-tv-card" : "live-tv-card-active"}>
          <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
            <div className="d-flex mt-1 ms-1 ">
              <MdScreenshotMonitor className="tv-icon" />
              <p className="ms-2 mb-2">LIVE TV</p>
            </div>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value={active}
                onChange={() => setActive(!active)}
              />
            </div>
          </div>
          {active && (
            <iframe
              allowFullScreen={true}
              style={{
                width: "100%",
                height: "210px",
                border: "none",
                opacity: "1",
                visibility: "visible",
                color: "white ",
              }}
              id="frame_video_2"
              src={`https://betexch.online/AViframe/${id}`}
            ></iframe>
          )}
        </div>
        <div className="matched-bets mt-3">
          <div className="d-flex justify-content-between tv-card mb-0 pb-0 p-1">
            {/* <div className="d-flex mt-1 ms-1 mb-0 "> */}

            <div className="w-100" style={{ height: "200px" }}>
              <div className="table-responsive maintableD "  style={{ height: "198px"}}>
                <table className="table w-100">
                  <thead className="w-100">
                    <tr>
                      <th style={{ width: "45%" }} scope="col">
                        Matched Bet
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Odds
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Stake
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Profit
                      </th>
                    </tr>
                  </thead>
                  {dashbetHistory.length > 0 ? (
                    <tbody>
                      {dashbetHistory?.map((item) => {
                        return item.back > 0 ? (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#0b9cf1" }}>
                            <td style={{ width: "45%" }}>{item.team}</td>
                            <td style={{ width: "15%" }}>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td style={{ width: "15%" }}>{item.stake}</td>
                            <td style={{ width: "15%" }}>{item.profit || 0}</td>
                          </tr>
                        ) : (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#f37a13" }}>
                            <td style={{ width: "45%" }}>{item.team}</td>
                            <td style={{ width: "15%" }}>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td style={{ width: "15%" }}>{item.stake}</td>
                            <td style={{ width: "15%" }}>{item.profit || 0}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-white">No Bet Place !</tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* <div className="bet-matches ms-2 p-2">AUSTRALIA VS NEWZEELAND</div>
          <div className="no-bets ms-1 p-2">NO BETS</div> */}
        </div>
      </div>
    </div>
  );
};

export default LiveTvSection;
