import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const FundTransferHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [leftreferralApi, setleftreferralApi] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);

  const userId = useUsername();
  const token = useToken();
  const referral_API = async () => {
    try {
      console.log("userId-->", userId);
      let responceRight = await API?.post(
        `userToUserFundTransferReport`,
        {
          username: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("History-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: item.row,
            user_id: item.uid,
            to_userid: item.to_userid,
            amount: item.amount,
            Edate: item.dd,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "From User Id", accessor: "user_id" },
      { Header: "To User Id", accessor: "to_userid" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Edate", accessor: "Edate" },
    ],
  });

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Fund Transfer History</h4>

            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundTransferHistory;
