import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./ColorGame.css";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaRegMinusSquare } from "react-icons/fa";
import io from "socket.io-client";
import ShowModal from "./ColorGameModal";
import { useDispatch } from "react-redux";
import { API } from "../../API/API";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const ColorGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useUserId();
  const jwt_token = useToken();
  // const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState();
  const [roundresult, setRoundresult] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [Betdata, setBetData] = useState(null);
  const [roundDetails, setRoundDetails] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [historyDataAdmin, sethistoryDataAdmin] = useState([]);
  const [remainingTime, setRemainingTime] = useState(0);
  const [loader, setloader] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const numberButton = [
    {
      className: "violet-btn voilet",
      value: 0,
      label: "0",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 1,
      label: "1",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 2,
      label: "2",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 3,
      label: "3",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 4,
      label: "4",
      color: "Red",
      btn: false,
    },
    {
      className: "violet-btn vt",
      value: 5,
      label: "5",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 6,
      label: "6",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 7,
      label: "7",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 8,
      label: "8",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 9,
      label: "9",
      color: "Green",
      btn: false,
    },
  ];

  const colorButton = [
    {
      className: "Green-button",
      color: "GREEN",
      label: "GREEN",
      value: 10,
      btn: true,
    },
    {
      className: "violet-button ",
      color: "VIOLET",
      label: "VIOLET",
      value: 20,
      btn: true,
    },
    {
      className: "red-button",
      color: "RED",
      label: "RED",
      value: 30,
      btn: true,
    },
  ];

  const bigSmallButton = [
    {
      className: "btas",
      color: "Big",
      label: "Big",
      value: 40,
      btn: true,
    },
    {
      className: "btas1",
      color: "Small",
      label: "Small",
      value: 50,
      btn: true,
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const ColorResult_API = async () => {
    try {
      // alert(userId);
      let responseHistory = await API.post(
        "getColorbetHistory_New",
        {
          uid: userId,
          LoginId: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHistoryData(responseHistory?.data?.data[0] || []);

      let responseHistoryAdmin = await API.post(
        "getColorbetHistory_New",
        {
          uid: userId,
          LoginId: "0",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      sethistoryDataAdmin(responseHistoryAdmin.data.data[0]);
      // dispatch(fetchuserBalance({ id: userId, token: jwt_token }));
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
      // console.log("Error While calling userinfomyReferrals API", e);
      // if(e.status==401){
      //   dispatch(logoutUser())
      //   sessionStorage.clear()
      // }
    }
  };

  useEffect(() => {
    ColorResult_API();
  }, [historyBoolean, loader]);

  const SOCKET_URL = "https://playstar-socket-api.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetInsertRound");

    cricketMatchesSocket.on("GetInsertRound_FromAPI", (data) => {
      // console.log("colorPlay", data[0]);

      if (data[0]) {
        setRoundDetails(data[0]);
      }

      const startRoundTime = new Date(data[0].startround).getTime();
      const endRoundTime = new Date(data[0].endround).getTime();
      const currentTime = new Date(data[0].currentDate).getTime();
      // console.log("Current Round ", currentTime);

      const timeDifferenceInSeconds = Math.floor(
        (endRoundTime - currentTime) / 1000
      );
      // console.log("diff", timeDifferenceInSeconds);

      const remainingCount1 = Math.max(0, timeDifferenceInSeconds);

      setRemainingTime(remainingCount1);
      // console.log("remainingCount1", remainingCount1);
      if (remainingCount1 === 0) {
        setloader(true);
      } else {
        setloader(false);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  function handleBetButton(btn) {
    if (remainingTime > 10 && roundDetails && roundDetails.id) {
      // console.log("btn", btn, roundDetails);
      let newData = { ...roundDetails, ...btn };
      // console.log("round", newData);
      setBetData(newData);
      setModal(true);
    }
  }

  const handleBetModalClose = () => {
    setModal(false);
    setBetData(null);
  };

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("getColorbetHistory", userId);
  //   cricketMatchesSocket.on("getColorbetHistory_FromAPI", (data) => {
  //     console.log("History-->", data);
  //     setHistoryData(data);
  //   });

  //   const cricketMatchesSocketAdmin = io(SOCKET_URL);
  //   cricketMatchesSocketAdmin.emit("getColorbetHistory", "0");
  //   cricketMatchesSocketAdmin.on("getColorbetHistory_FromAPI", (data) => {
  //     console.log("History-->Admin", data);
  //     sethistoryDataAdmin(data);
  //   });

  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //     cricketMatchesSocketAdmin.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   const ColorGameSocket = io(SOCKET_URL);
  //   ColorGameSocket.emit("GetColourResultSocket");
  //   ColorGameSocket.on("GetColourResultSocket_FromAPI", (data) => {
  //     console.log("roundresult-->", data);
  //     setRoundresult(data);
  //   });

  //   return () => {
  //     ColorGameSocket.disconnect();
  //   };
  // }, []);

  return (
    <>
      {/* <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer /> */}
      <div className="col-lg-10 home">
        <div className="row home">
          <div className="col-lg-8 p-2 center-div">
            <div className="color-game-div pt-2 p-2">
              <div className="color-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">Color Game</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    {/* Round ID: #
                    {roundDetails && roundDetails.id ? roundDetails.id : "N/A"}{" "} */}
                    | Left Time: {remainingTime} | Rules
                  </p>
                </div>
              </div>

              <div className="card  color-game-card">
                {loader && (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div className="loading-text">Loading...</div>
                  </div>
                )}
                <div className="row mt-4">
                  {colorButton.map((btn, index) => (
                    <div className="col-4" key={index}>
                      <button
                        className={btn.className}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        readOnly
                      >
                        {btn.label}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="row mt-4">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 d-flex justify-content-between">
                    {numberButton.slice(0, 5).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                        readOnly
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 d-flex justify-content-between">
                    {numberButton.slice(5, 10).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  {" "}
                  {bigSmallButton.map((btn, index) => (
                    <div className="col-6" key={index}>
                      <button
                        className={btn.className}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        readOnly
                      >
                        {btn.label}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* <-------------------------------Previous-ROund-Results-table----------> */}
              <div>
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>Previous Rounds Results</p>
                  </div>
                </div>
                <div className="table-h-div row">
                  <table className="tableColor table-responsive">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Number</th>
                      <th>Result</th>
                      <th>B/M</th>
                    </tr>
                    {historyDataAdmin.map((item) => (
                      <>
                        <tr key={item?.BetID}>
                          <td className="align-items-center ps-2">
                            {item?.gamesno}
                          </td>
                          {item?.status != 0 && (
                            <>
                              <td className="align-items-center ps-2">
                                {item?.number}
                              </td>
                              <td className="align-items-center ps-2">
                                {item?.number != null && item?.number !== "" ? (
                                  item?.number >= 0 && item?.number <= 9 ? (
                                    item?.number == "0" ? (
                                      <>
                                        <div className="ds">
                                          <span className="violet-dot"></span>
                                          <span className="red-dot"></span>
                                        </div>
                                      </>
                                    ) : item?.number == "5" ? (
                                      <>
                                        <div className="ds">
                                          <span className="violet-dot"></span>
                                          <span className="green-dot"></span>
                                        </div>
                                      </>
                                    ) : item?.number % 2 === 0 ? (
                                      <>
                                        <div className="ds">
                                          <span className="red-dot"></span>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="ds">
                                          <span className="green-dot"></span>
                                        </div>
                                      </>
                                    )
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                            </>
                          )}
                          <td className="align-items-center ps-2">
                            {item?.NumberStatus}
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                  {/* <div className="col-3 d-flex align-items-center justify-content-center ps-2">
                    Period
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Number
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Result
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    B/M
                  </div> */}
                </div>
                {/* <div className="table-overflow">
                {historyDataAdmin.map((item) => (
                  <>
                    <div className="table-inner-row table-overflow row" key={item?.BetID}>
                      <div className="col-3 d-flex align-items-center justify-content-center  ps-2">
                        {item?.gamesno}
                      </div>
                      {item?.status !== 0 && (
                        <>
                          <div className="col-3 d-flex align-items-center justify-content-center px-2">
                            {item?.number}
                          </div>
                          <div className="col-3 d-flex align-items-center justify-content-center">
                            {item?.number != null && item?.number !== "" ? (
                              item?.number >= 0 && item?.number <= 9 ? (
                                item?.number == "0"  ? (
                                  <>
                                  <div className="violet-dot"></div>
                                  <div className="red-dot"></div>
                                  
                                  </>
                                ) :
                                item?.number == "5" ? (
                                  <>
                                  <div className="violet-dot"></div>
                                  <div className="green-dot"></div>
                                  
                                  </>
                                ) :
                                item?.number % 2 === 0 ? (
                                  <div className="red-dot"></div>
                                ) : (
                                  <div className="green-dot"></div>
                                )
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}

                      <div className="col-3 d-flex align-items-center justify-content-center  ps-2">
                        {item?.NumberStatus}
                      </div>
                    </div>
                  </>
                ))}
                </div> */}
              </div>

              {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>My Bets</p>
                  </div>
                </div>

                <div className="table-h-div row" style={{overflow:'auto'}}>
                  <table className="tableColor table-responsive">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Select</th>
                      <th>Result</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                    {historyData.map((item) => (
                      <>
                        <tr key={item?.BetID}>
                          <td className="align-items-center ps-2">
                            {item?.gamesno}
                          </td>
                          <td className="align-items-center ps-2">
                            {item?.ColorBet}
                          </td>
                          {item?.status == 0 && (
                            <>
                              <td className="align-items-center fail">
                                Pending
                              </td>
                              <td className="align-items-center ps-2">
                                {item?.stake < 100
                                  ? parseFloat(item?.stake).toFixed(2)
                                  : item?.stake < 1000
                                  ? parseFloat(item?.stake).toFixed(1)
                                  : `${(parseFloat(item?.stake) / 1000).toFixed(
                                      2
                                    )}K`}
                              </td>
                            </>
                          )}
                          {item?.status != 0 && (
                            <>
                              <td
                                className={`align-items-center ${
                                  item?.status == 1 ? "success" : "fail"
                                }`}
                              >
                                {item?.status == 1 ? "Win" : "Loss"}
                              </td>
                              <td
                                className={`align-items-center ${
                                  item?.resultStatus == 0 ? "fail" : "success"
                                }`}
                              >
                                {item?.stake < 100
                                  ? parseFloat(item?.stake).toFixed(2)
                                  : item?.stake < 1000
                                  ? parseFloat(item?.stake).toFixed(1)
                                  : `${(parseFloat(item?.stake) / 1000).toFixed(
                                      2
                                    )}K`}
                              </td>
                            </>
                          )}
                          <td className="align-items-center ps-2">
                            <div
                              onClick={() => {
                                toggle === item?.BetID
                                  ? setToggle(null)
                                  : setToggle(item?.BetID);
                              }}
                            >
                              {toggle === item?.BetID ? (
                                <FaRegMinusSquare className="plus-icon" />
                              ) : (
                                <FaRegPlusSquare className="plus-icon" />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5">
                            {toggle === item?.BetID && (
                              <>
                                <table className="table-responsive">
                                  <tr>
                                    <th colSpan="2">Period Detail</th>
                                  </tr>
                                  <tr>
                                    <td>Period</td>
                                    <td>{item?.gamesno}</td>
                                  </tr>
                                  <tr>
                                    <td>Contract Money</td>
                                    <td>{item?.stake}</td>
                                  </tr>
                                  <tr>
                                    <td>Delivery</td>
                                    <td>{item?.odds}</td>
                                  </tr>
                                  <tr>
                                    <td>Fee</td>
                                    <td>{item?.Charge}</td>
                                  </tr>
                                  <tr>
                                    <td>Result</td>
                                    <td>
                                      {item?.status === 1
                                        ? "Win"
                                        : item?.status === 2
                                        ? "Loss"
                                        : ""}

                                      {item?.resultColor === "Green" ? (
                                        <div className="green-dot ms-2"></div>
                                      ) : item?.resultColor === "Red" ? (
                                        <div className="red-dot ms-2"></div>
                                      ) : item?.resultColor === "Violet" ? (
                                        <>
                                          {" "}
                                          <div className="violet-dot ms-2"></div>
                                          {/* <div className="red-dot ms-2"></div> */}
                                        </>
                                      ) : item?.resultColor === "Violet" ? (
                                        <>
                                          {" "}
                                          <div className="violet-dot ms-2"></div>
                                          {/* <div className="green-dot ms-2"></div> */}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Select</td>
                                    <td>{item?.ColorBet}</td>
                                  </tr>
                                  <tr>
                                    <td>Amount</td>
                                    <td
                                      className={`col-6 pt-1  ${
                                        item?.resultStatus == 0
                                          ? "fail"
                                          : "success"
                                      }`}
                                    >
                                      {item?.stake}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Create Time</td>
                                    <td>{item?.dd}</td>
                                  </tr>
                                </table>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                  {/* <table>
                    <tr>
                      <th>Period</th>
                      <th>Select</th>
                      <th>Result</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                    <tr>
                      <td>Alfreds Futterkiste</td>
                      <td>Maria Anders</td>
                      <td>Germany</td>
                      <td>Maria Anders</td>
                      <td>Germany</td>
                    </tr>
                    
                  </table> */}

                  {/* <div className="col-3  align-items-center default">
                    Period
                  </div>
                  <div className="col-2  align-items-center default">
                    Select
                  </div>
                  <div className="col-2 align-items-center default">
                    Result
                  </div>
                  <div className="col-2 align-items-center default">
                    Amount
                  </div>
                  <div className="col-3 align-items-center default">
                    Details
                  </div> */}
                </div>
                {/* <div className="table-overflow">
                {historyData.map((item) => (
                  <>
                    <div className="table-inner-row row" key={item?.BetID}>
                      <div className="col-3 align-items-center ps-2">
                        {item?.gamesno}
                      </div>
                      <div className="col-2 align-items-center ps-2">
                        {item?.ColorBet}
                      </div>
                      {item?.status == 0 && (
                        <>
                        <div className="col-2 align-items-center ps-2">
                          Pending
                        </div>
                        <div className="col-2 align-items-center ps-2">
                         {item?.stake < 100
                           ? parseFloat(item?.stake).toFixed(2)
                           : item?.stake < 1000
                             ? parseFloat(item?.stake).toFixed(1)
                             : `${(parseFloat(item?.stake) / 1000).toFixed(
                               2
                             )}K`}
                       </div>
                       </>
                      )}

                      {item?.status != 0 && (
                        <>
                          <div
                            className={`col-2  align-items-center ${item?.status == 1 ? "success" : "fail"
                              }`}
                          >
                            {item?.status == 1 ? "Win" : "Loss"}
                          </div>

                          <div
                            className={`col-2 align-items-center ${item?.resultStatus == 0 ? "fail" : "success"
                              }`}
                          >
                            {item?.stake < 100
                              ? parseFloat(item?.stake).toFixed(2)
                              : item?.stake < 1000
                                ? parseFloat(item?.stake).toFixed(1)
                                : `${(parseFloat(item?.stake) / 1000).toFixed(
                                  2
                                )}K`}
                          </div>
                        </>
                      )}

                      <div className="col-3 align-items-center ps-2">
                        <div
                          onClick={() => {
                            toggle === item?.BetID
                              ? setToggle(null)
                              : setToggle(item?.BetID);
                          }}
                        >
                          {toggle === item?.BetID ? (
                            <FaRegMinusSquare className="plus-icon"  />
                          ) : (
                            <FaRegPlusSquare className="plus-icon" />
                          )}
                        </div>
                      </div>
                      
                    </div>
                    {toggle === item?.BetID && (
                      <div className="card card2 bet-menu-card">
                        <div className="bet-menu-card-h text-white text-center">
                          Period Detail
                        </div>
                        <div className="row">
                          <div className="col-6 px-3 pt-1">Period</div>
                          <div className="col-6  pt-1">{item?.gamesno}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Contract Money</div>
                          <div className="col-6 pt-1">{item?.stake}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Delivery</div>
                          <div className="col-6 pt-1">{item?.odds}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Fee</div>
                          <div className="col-6 pt-1">{item?.Charge}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Result</div>
                          <div className="col-6 pt-1 ">
                            {item?.status === 1
                              ? "Win"
                              : item?.status === 2
                                ? "Loss"
                                : ""}

                            {item?.resultColor === "Green" ? (
                              <div className="green-dot ms-2"></div>
                            ) : item?.resultColor === "Red" ? (
                              <div className="red-dot ms-2"></div>
                            ) : item?.resultColor === "Violet" ? (
                              <>
                                {" "}
                                <div className="violet-dot ms-2"></div>
                                
                              </>
                            ) : item?.resultColor === "Violet" ? (
                              <>
                                {" "}
                                <div className="violet-dot ms-2"></div>
                                
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1 text-center">Select</div>
                          <div className="col-6  pt-1"> {item.ColorBet}</div>
                         
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Amount</div>
                          <div
                            className={`col-6 pt-1  ${item?.resultStatus == 0 ? "fail" : "success"
                              }`}
                          >
                            {" "}
                            {item?.stake}
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Create Time</div>
                          <div className="col-6 pt-1 ">{item?.dd}</div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
                </div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {modal && (
            <ShowModal
              historyBoolean={historyBoolean}
              setHistoryBoolean={setHistoryBoolean}
              open={setModal}
              data={Betdata}
              onClose={handleBetModalClose}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ColorGame;
