import React, { useEffect, useState } from "react";
import { fetchUserdepositHistoryData } from "../../Redux/Slices/depositHistorySlice";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

export default function DepositBonus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);

  const userId = useUserId();
  const token = useToken();
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.post(
        `DepositeHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("LeaderShip-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            user_id:item.user_id,
            Amount:item.Amount,
            dd:item.dd,
            TotalAmount:item.TotalAmount,
            ExtraAmount:item.ExtraAmount
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "UserId", accessor: "user_id" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Bonus", accessor: "ExtraAmount" },
      { Header: "Total Amount", accessor: "TotalAmount" },
      { Header: "Date", accessor: "dd" },
      // { Header: "Remark", accessor: "remark" },
      // { Header: "Narration", accessor: "remark" },
    ],
  });
  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Deposit Bonus</h4>

            <Table data={[...currentPost]} columns={matching_income.cols} />
            <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={leftreferralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
