import React, { useEffect, useState } from "react";
import "./Mlmdashboard.css";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/MLM dashB/2.png";
import b from "../../Media/MLM dashB/5.png";
import c from "../../Media/MLM dashB/8.png";
import d from "../../Media/MLM dashB/totalw.png";
import logo from "../../Media/MLM dashB/logo.png";
import logos from "../../Media/MLM dashB/logos.png";
import withdrawal from "../../Media/MLM dashB/withdrawal.png";
import gamewallet from "../../Media/MLM dashB/gamewallet.png";
import direct from "../../Media/MLM dashB/direct.png";
import totalteam from "../../Media/MLM dashB/totalteam.png";
import teambusiness from "../../Media/MLM dashB/teambusiness.png";
import deposit from "../../Media/MLM dashB/deposit.png";
import recharge from "../../Media/MLM dashB/recharge.png";
import level from "../../Media/MLM dashB/level.png";
import dailysalary from "../../Media/MLM dashB/dailysalary.png";
import win from "../../Media/MLM dashB/win.png";
import bonus from "../../Media/MLM dashB/bonus.png";
import leadership from "../../Media/MLM dashB/leadership.png";
import rank from "../../Media/MLM dashB/rank.png";
import leaderincome from "../../Media/MLM dashB/leaderincome.png";
import totalincome from "../../Media/MLM dashB/totalincome.png";
import totalwithdrawal from "../../Media/MLM dashB/totalwithdrawal.png";
import fund from "../../Media/MLM dashB/fund.png";
import net from "../../Media/MLM dashB/net.png";
import { toast } from "react-toastify";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

export default function Mlmdashboard() {
  let uId = useUserId();
  const token = useToken();
  const username=useUsername()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dashboard, setdashboard] = useState("");

  useEffect(() => {
    const DashboardValues = async () => {
      try {
        let resnetbal = await API.get(
          `/userDashboard?uid=${uId}&username=${username}`,

          {
            headers: {
              Authorization: token,
            },
          }
        );
        // console.log("mlmDash", resnetbal.data.data[0][0]);
        setdashboard(resnetbal.data.data[0][0]);
      } catch (e) {
        console.log("Error While Fatch Dashboard API", e);
        if (
          e.response.data.showableMessage ===
          "Please login to access this resource"
        ) {
          sessionStorage.clear();
          dispatch(logoutUser());
          navigate("/");
        }
      }
    };

    DashboardValues();
  }, []);

  let URL = window.location.origin;
  const CopyReferralLink = () => {
    const text = `${URL}/registration?userid=${uId}`;
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  return (
    <div className="col-lg-10 ">
      <div className="row ">
        <div
          className="col-lg-8"
          style={{ height: "89vh", overflowY: "scroll" }}
        >
          <div className="container-fluid">
            <div className="row leftsidecontent ">
              <div className="col-xl-6 col-md-6 col-12">
                <div className="card border-0 topcard profilcard h-100">
                  <div className="card-body">
                    <div className="card_profileimg mb-3">
                      <img
                        className="w-100 h-100 rounded-pill"
                        src={logos}
                        alt="profile"
                      />
                    </div>
                    <div className="userid">User Id: {dashboard.userid}</div>
                    <div className="wallet ">Affiliate Link</div>
                    <p
                      className="text-center copytext border-theme1"
                      onClick={CopyReferralLink}
                      id="walletAddress"
                    >
                      Copy Referral Link
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-12 ">
                <div className="card topcard border-0 h-100 p-3">
                  <div className="row mx-0 h-100">
                    <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                      <div className="totoinvestment">
                        <img
                          className="w-100 h-100 rounded-pill"
                          src={logo}
                          alt="Total_Investment"
                        />
                      </div>
                      <div className="heading">Total Investment</div>
                      <div className="total fw-bold text-black text-center">
                        {dashboard.totalinvestment}
                      </div>
                    </div>
                    <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                      <div className="totoinvestment">
                        <img
                          className="w-100 h-100 rounded-pill"
                          src={logo}
                          alt="Kick_Investment"
                        />
                      </div>
                      <div className="heading">Wallet</div>
                      <div className="total fw-bold text-black text-center">
                        {dashboard.NetbalanceIncome}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mx-0 my-3 samllcardsection">
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/MatrixDownline"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={c}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Rank</div>
                          <div className="sub_heading">
                            {dashboard.rank_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/stakingGlobalteam"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={gamewallet}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Game Wallet</div>
                          <div className="sub_heading">
                            {dashboard.Net_GameFund}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/WithdrawalUSDT">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={withdrawal}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Withdrawal Wallet
                            </div>
                            <div className="sub_heading">
                              {dashboard.withdawlamount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/stakingGlobalteam"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={direct}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Direct Member</div>
                          <div className="sub_heading">
                            {dashboard.directteam}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/myTeam">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={totalteam}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Total Team</div>
                            <div className="sub_heading">
                              {dashboard.TotalTeam}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/LegBussiness">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={d}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Direct Bussiness</div>
                            <div className="sub_heading">
                              {dashboard.directBusiness}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/LegBussiness">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={teambusiness}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Team Bussiness</div>
                            <div className="sub_heading">
                              {dashboard.teamBusiness}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/Signupbonus">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={a}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Signup Bonus</div>
                            <div className="sub_heading">
                              {dashboard.Signup_bonus}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/myReferral">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={b}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Referral Bonus</div>
                            <div className="sub_heading">
                              {dashboard.Refferal_bonus}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/Deposit">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={deposit}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Deposit Bonus</div>
                            <div className="sub_heading">
                              {dashboard.totalDeposit}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/TeamRechargebonus">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={recharge}
                                alt="NetIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Team Recharge Bonus
                            </div>
                            <div className="sub_heading">
                              {dashboard?.Downline_Recharge_Bonus}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/leadershipIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={level}
                                alt="TotalIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Leadership Level Salary{" "}
                            </div>
                            <div className="sub_heading">
                              {dashboard.LeadershipLevelSalery_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/DailySaleryincome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={dailysalary}
                                alt="TotalIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Daily Salary</div>
                            <div className="sub_heading">
                              {dashboard.Daily_Salery_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/LevelincomeTraders">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={win}
                                alt="TotalIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Win Level Income</div>
                            <div className="sub_heading">
                              {dashboard.Level_income_Traders}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/loginBonus">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={bonus}
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Daily Login Bonus
                            </div>
                            <div className="sub_heading">
                              {dashboard.login_bonus}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/UplineSalaryincome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={a}
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Upline Salary Income
                            </div>
                            <div className="sub_heading">
                              {dashboard.UplineSalery_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/LeadershipMonthlySaleryincome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={leadership}
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Leadership Monthly Salary
                            </div>
                            <div className="sub_heading">
                              {dashboard.LeadershipMonthlySalery_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/rankIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={rank}
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Rank and Reward Income
                            </div>
                            <div className="sub_heading">
                              {dashboard.Rank_Reward_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/WeeklyIncomeReport">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={leaderincome}
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Top 10 Leader Income
                            </div>
                            <div className="sub_heading">
                              {dashboard.Top10Leaderincome}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={totalincome}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Income</div>
                          <div className="sub_heading">
                            {dashboard.totalincome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/WithdrawalHistory">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={totalwithdrawal}
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Total Withdrawal</div>
                            <div className="sub_heading">
                              {dashboard.withdawlamount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  <Link to="/ConvertIntoGameHistory">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src={fund}
                                alt="NetIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Convert Income Fund
                            </div>
                            <div className="sub_heading">
                              {dashboard.convertincomefund}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={net}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Net Balance</div>
                          <div className="sub_heading">
                            {dashboard.NetbalanceIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  );
}
