import React, { useState } from "react";
import "./BettingHistory.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBetHistoryData } from "../../Redux/Slices/betHistorySlice";
import { Scrollbars } from "react-custom-scrollbars-2";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useNavigate } from "react-router-dom";
import { fetchBetHistory } from "../../Redux/api/betHistoryAPI";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const BettingHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { betHistory, isLoading, error } = useSelector(
    (state) => state.betHistory
  );
  const [tableData, setTableData] = useState([]);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const invalidToken = useSelector(state => state.betHistory.invalidToken);

  useEffect(() => {
    if (invalidToken) {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("token");
      navigate ('/');
    }
  }, [invalidToken]);

  const token = useToken();
  // console.log("storedJwtToken ",storedJwtToken)
  const userId = useUserId();
  // console.log("stored userdata ",userdata);

  // useEffect(() => {
  //   dispatch(fetchBetHistoryData({ id: userId, token }));
  // }, [dispatch, userId, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          uid: userId,
          fromDate: "",
          toDate: "",
        };
        const response = await fetchBetHistory(data, token);
        setTableData(response);
      } catch (error) {
        console.error("Error fetching bet history:", error);
      }
    };

    fetchData();
  }, [userId, token]);

  

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = tableData.slice(indexOfFirstPage, indexOfLastPost);


  const handleSearch = () => {
    const data = {
      uid: userId,
      fromDate: fromdate,
      toDate: todate,
    };
    dispatch(fetchBetHistoryData({ data, token }));
  };

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Betting History </h4>

            <div className="dates-div p-1 d-flex row">
              <div className="col-md-3 mt-1">
                <div className="d-flex">
                  <div className="from d-flex align-items-center justify-content-center">
                    From:
                  </div>
                  <div className="">
                    <input
                      className="from-control"
                      type="date"
                      value={fromdate}
                      onChange={(e) => setfromdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 mt-1">
                <div className="d-flex ">
                  <div className="from d-flex align-items-center justify-content-center">
                    To:
                  </div>
                  <div className="">
                    <input
                      className="from-control"
                      type="date"
                      value={todate}
                      onChange={(e) => settodate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2 mt-1">
                <button
                  className="btn btn-success ms-2"
                  onClick={() => handleSearch()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>{" "}
                  Search
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="tablee">
                <thead className="Statement_head">
                  <tr>
                    <th>S No.</th>
                    <th>Matched Bet</th>
                    <th>Odds</th>
                    <th>Remark</th>
                    <th>Match</th>
                    <th>Back</th>
                    <th>Lay</th>
                    <th>Date & Time</th>
                    <th>Ball Session</th>
                    <th>Match Id</th>
                    <th>Match Status</th>
                    <th>Status</th>
                    <th>Stake</th>
                    <th>Win Amount</th>
                  </tr>
                </thead>
                {/* {currentPost?.map((item) => (
                                return()
                                {item.back >0 ? <></>:<></>} */}
                <tbody>
                  {currentPost.map((item, index) =>
                    item.back > 0 ? (
                      <tr key={item.id} style={{ backgroundColor: "#0b9cf1" }}>
                        <td>{index + 1}</td>
                        <td>{item.team}</td>
                        <td>{item.type}</td>
                        <td>{item.remark}</td>
                        <td>{item.name}</td>
                        <td>{item.back}</td>
                        <td>{item.lay}</td>
                        <td>{item.edate}</td>
                        <td>{item.ballsess}</td>
                        <td>{item.matchid}</td>
                        {item.status == "1" ? (
                          <td>
                            {item.MatchStatus == "0" ? (
                              <div className="result3 text-center">-</div>
                            ) : (
                              <div className="result4 text-center">+</div>
                            )}
                          </td>
                        ) : (
                          <td>
                            {item.lay > 0 ? (
                              <div className="result51" id="result9">
                                .
                              </div>
                            ) : (
                              <div className="result50">.</div>
                            )}
                          </td>
                        )}
                        <td>{item.status == "0" ? "Unsettled" : "Settled"}</td>
                        <td>{item.stake}</td>
                        <td>{item.winamount}</td>
                      </tr>
                    ) : (
                      <tr key={item.id} style={{ backgroundColor: "#f37a13" }}>
                        <td>{index + 1}</td>
                        <td>{item.team}</td>
                        <td>{item.type}</td>
                        <td>{item.remark}</td>
                        <td>{item.name}</td>
                        <td>{item.back}</td>
                        <td>{item.lay}</td>
                        <td>{item?.edate}</td>
                        <td>{item.ballsess}</td>
                        <td>{item.matchid}</td>
                        {item.status == "1" ? (
                          <td>
                            {item.MatchStatus == "0" ? (
                              <div className="result3 text-center">-</div>
                            ) : (
                              <div className="result4 text-center">+</div>
                            )}
                          </td>
                        ) : (
                          <td>
                            {item.lay > 0 ? (
                              <div className="result51" id="result9">
                                .
                              </div>
                            ) : (
                              <div className="result50">.</div>
                            )}
                          </td>
                        )}
                        <td>{item.status == "0" ? "Unsettled" : "Settled"}</td>
                        <td>{item.stake}</td>
                        <td>{item.winamount}</td>
                      </tr>
                    )
                  )}
                </tbody>
                {/* ))} */}
              </table>
            </div>
            <div className="">
              {/* <Table data={[...currentPost]} columns={matching_income.cols} /> */}
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={tableData.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingHistory;
