import React, { useEffect, useState } from "react";
import "../Deposit/Deposit.css";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

function ConvertintoGameWallet() {
  const token = useToken();
  const userId = useUserId();
  let uId = useUsername();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [old, setData] = useState("0.00");
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState("");

  const hendelclick = (event) => {
    let value = event.target.value;
    let sanitizedValue = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
    sanitizedValue = sanitizedValue.replace(/[^0-9]/g, "");
    setAmount(sanitizedValue);
    // ConverAmount(sanitizedValue);
  };

  const Convert_API = async () => {
    try {
      let res = await API.post(
        "convertIncomeToGame",
        {
          uid: userId,
          amount: amount,
          // type: "",
          // password: password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Withdrwal", res.data);
      // setscoreboard_data(res.data)
      if (res.data.data === "Success") {
        toast.success(res.data.data);
        // Live_Rate();
        setAmount("");
      } else {
        toast.error(res.data.data);
      }
      //}
    } catch (error) {
      console.log("Something Error in Withdrwal API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 px-2 p-1">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Convert Income Fund To Game Wallet</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className=" card deposit-card mg-lg-4 mt-2 py-2 col-lg-6 mb-4">
                <div className="px-4">
                  {/* <div className="row mt-2">
                    <div className="col-md-12 px-1">
                      <div className="form-group">
                        <label htmlFor="avl_bl">Available Balance</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="avl_bl"
                          placeholder="Available Balance"
                          value={old}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-1">
                    <div className="col-md-12 px-1">
                      <div className="form-group">
                        <label htmlFor="amount">Enter Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="amount"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => hendelclick(e)}
                          maxLength={10}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-1">
                    <div className="col-md-12 px-1">
                      <label htmlFor="pass">Transaction Password</label>
                      <input
                        className="form-control mt-2"
                        id="pass"
                        type="password"
                        placeholder="Transaction Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div> */}

                  <button
                    className="btn with-f-btn mt-lg-4 mt-2 "
                    onClick={() => Convert_API()}
                  >
                    Convert
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConvertintoGameWallet;
