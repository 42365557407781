import { useSelector } from "react-redux";
import { decryptData } from "../libs/userEncryption";

export const useUsername = () => {
  const encryptedUsername = useSelector((state) => state.auth.username);
  return decryptData(encryptedUsername);
};

export const useUserId = () => {
  const encryptedUserId = useSelector((state) => state.auth.userId);
  return decryptData(encryptedUserId);
};

export const useToken = () => {
  const encryptedToken = useSelector((state) => state.auth.token);
  return decryptData(encryptedToken);
};
