import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../API/API";

export const fetchSessionStackData = createAsyncThunk(
  "sessionMarket/stack",
  async ({ userId, id, country, token,username }) => {
    try {
      const res = await API.post(
        `/getSMtempexposure`,
        {
          uid: userId,
          matchid: id,
          mname: country,
          username
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data.data;
    } catch (err) {
      throw err;
    }
  }
);
