import React, { useEffect, useState } from "react";
import "../Deposit/Deposit.css";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchBetaWalletData,
//   selectBetaWalletInfo,
// } from "../../Redux/Slices/betaWalletSlice";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useToken, useUserId, useUsername } from "../../Utility/hooks/usershook";

const Withdrawal = () => {
  const token = useToken();
  const userId = useUserId();
  let username = useUsername();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const info = useSelector(selectBetaWalletInfo);
  // console.log("info", info);
  // useEffect(() => {
  //   dispatch(fetchBetaWalletData({ id: "USERID780362", token }));
  // }, []);

  const [old, setData] = useState("0.00");
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [valueData, setValueData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [select, setSelect] = useState("");
  const [branch, setBranch] = useState("");
  const [account, setAccount] = useState("");

  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        console.log("account", accounts[0]);
        setInputValue(accounts[0]);
      } else {
        console.log("eee", "MetaMask not found");
      }
    } catch (error) {
      console.error("ggg", error);
    }
  };

  // useEffect(() => {
  //   connectToMetaMask();
  // }, []);

  const hendelclick = (event) => {
    let value = event.target.value;
    let sanitizedValue = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
    sanitizedValue = sanitizedValue.replace(/[^0-9]/g, "");
    setAmount(sanitizedValue);
    ConverAmount(sanitizedValue);
  };

  const ConverAmount = async (value) => {
    try {
      let res = await API.get(
        `WithdrawlConversationAmount?PtcAmount=${value}&username=${username}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      res = res.data.data[0];
      console.log("ConverAmount", res);
      setValueData(res);
    } catch (e) {
      console.log("Something Error in ConvertAmount API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const Live_Rate = async () => {
    try {
      let res = await API.get(
        `/userDashboard?uid=${userId}&username=${username}`,

        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("old", res);
      res = res.data.data[0][0].NetbalanceIncome;
      setData(res);
    } catch (e) {
      console.log("Error While Fatch  Live_RateAPI", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Live_Rate();
  }, []);

  const Withdrwal_API = async () => {
    try {
      // if (inputValue == "No Wallet") {
      //   toast.error("No Wallet");
      //   // setconnected("Wallet is locked");
      // } else if (inputValue == "Wrong Network") {
      //   toast.error(" Wrong Network Please Connect BSc Network");
      //   console.log("Wrong Network Please Connect BSC Scan Network");
      //   // setconnected("Wrong Network");
      // } else if (inputValue == "") {
      //   toast.error("Please Connect Wallet First !!");
      // } else {
      if (!select) {
        toast.error("Select Transaction Type");
        return;
      }
      let res = await API.post(
        "UserWithdrawl",
        {
          uid: userId,
          amount: amount,
          type: select,
          password: password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Withdrwal", res.data);
      // setscoreboard_data(res.data)
      if (res.data.data === "SUCCESSFUL") {
        toast.success(res.data.data);
        setPassword("");
        setAmount("");
        setSelect("");
        setData("");
        Live_Rate();
      } else {
        toast.error(res.data.data);
      }
      //}
    } catch (error) {
      console.log("Something Error in Withdrwal API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const [bank, setBankName] = useState("");
  const Profile = async () => {
    try {
      let res = await API.post(
        `getbankDetails_byuserid`,
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      res = res.data.data[0];
      // console.log("Profile+>", res);
      if (res && res.length > 0) {
        setAccount(res[0].accountnumber);
        setBankName(res[0].bank_name);
      }
    } catch (e) {
      console.log("Somthing error in Profile API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (select === "INR") {
      Profile();
    }
  }, [select]);

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 px-2 p-1">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Withdrawal</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className=" card deposit-card mg-lg-4 mt-2 py-2 col-lg-6">
                <div className="px-4">
                  {/* <form> */}
                  <div className="row mt-2">
                    <div className="col-md-12 px-1">
                      <div className="form-group">
                        <label htmlFor="avl_bl">Available Balance</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="avl_bl"
                          placeholder="Available Balance"
                          value={old}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-12 px-1">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Select Transaction Type
                        </label>
                        <select
                          type="text"
                          
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          onChange={(e) => setSelect(e.target.value)}
                          value={select}
                        >
                          <option value=" ">Select</option>
                          <option value="INR">INR</option>
                          <option value="USDT">USDT</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-12 px-1">
                      <div className="form-group">
                        <label htmlFor="amount">Enter Amount (INR)</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="amount"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => hendelclick(e)}
                          maxLength={10}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {select === "USDT" && (
                      <div className="col-md-12 px-1">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">USD Amount</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            placeholder="Calculated USD Amount"
                            value={parseFloat(amount / 90).toFixed(4)}
                            disabled
                            maxLength={10}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mt-1">
                    {select === "INR" && (
                      <>
                        {" "}
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Bank Name
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="exampleInputEmail1"
                              placeholder="Bank Name"
                              value={bank}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Account Number
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="exampleInputEmail1"
                              placeholder="Account Number"
                              value={account}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* // </div> */}
                      </>
                    )}
                    <div className="col-md-12 px-1">
                      <label htmlFor="pass">Transaction Password</label>
                      <input
                        className="form-control mt-2"
                        id="pass"
                        type="password"
                        placeholder="Transaction Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <button
                    // type="submit"
                    className="btn with-f-btn mt-lg-4 mt-2 "
                    onClick={() => Withdrwal_API()}
                  >
                    Withdrawal Balance
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
